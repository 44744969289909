<style lang="scss">
    @media (max-width: 575.98px) {
        ul.list-group {
            margin: 0px;
        }
        ul.nav.nav-tabs{
        display: block !important;
        margin-left: 0px;
        margin-bottom: 20px;
    }
    }

</style>
<template>
    <div class="korisniciview">
        <div class="card user-card">
            <div class="card-body">
                <div class="row" v-if="!loading">
                    <div class="col-xl-6 col-lg-12 d-flex flex-column justify-content-between border-container-lg">
                        <div class="user-avatar-section">
                            <div class="d-flex justify-content-start">
                                <div class="d-flex flex-column ml-1">
                                    <div class="user-info mb-1">
                                        <h4 class="mb-0">{{user.ime+" "+user.prezime}}</h4>
                                        <span class="card-text">{{user.email}}</span>
                                    </div><!--
                                    <div class="d-flex flex-wrap">
                                        <a href="./app-user-edit.html" class="btn btn-primary waves-effect waves-float waves-light">Edituj</a>
                                        <button class="btn btn-outline-danger ml-1 waves-effect">Deaktiviraj</button>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-12 mt-2 mt-xl-0">
                        <div class="user-info-wrapper">
                            <div class="d-flex flex-wrap">
                                <div class="user-info-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mr-1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    <span class="card-text user-info-title font-weight-bold mb-0">Email:</span>
                                </div>
                                <p class="card-text mb-0">{{user.email}}</p>
                            </div>
                            <div class="d-flex flex-wrap my-50">
                                <div class="user-info-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check mr-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                    <span class="card-text user-info-title font-weight-bold mb-0">Status:</span>
                                </div>
                                <p class="card-text mb-0">{{user.active?"Aktivan":"Neaktivan"}}</p>
                            </div>
                            <div class="d-flex flex-wrap my-50">
                                <div class="user-info-title">
                                    <span class="card-text user-info-title font-weight-bold mb-0">Tip Korisnika:</span>
                                </div>
                                <p class="card-text mb-0">{{user.rank}}</p>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div class="user-info-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone mr-1"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    <span class="card-text user-info-title font-weight-bold mb-0">Kontakt:</span>
                                </div>
                                <p class="card-text mb-0">{{user.tel}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="basic-tabs-components">
            <div class="row match-height">
                <!-- Basic Tabs starts -->
                <div class="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="card-body">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" aria-controls="home" role="tab" aria-selected="true">Uplate</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" aria-controls="profile" role="tab" aria-selected="false">Testovi</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane active" id="home" aria-labelledby="home-tab" role="tabpanel">
                                    <section class="app-user-list">
                                        <div class="card">
                                            <div class="card-datatable table-responsive pt-0">
                                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div class="d-flex justify-content-between align-items-center header-actions mx-1 row mt-75">
                                                        <div class="col-lg-12 col-xl-6">

                                                        </div>
                                                        <div class="col-lg-12 col-xl-6 pl-xl-75 pl-0">
                                                            <div class="dt-action-buttons text-xl-right text-lg-left text-md-right text-left d-flex align-items-center justify-content-lg-end align-items-center flex-sm-nowrap flex-wrap mr-1">
                                                                <div class="dt-buttons btn-group flex-wrap">
                                                                    <button class="btn add-new btn-primary mt-50" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#modals-slide-in">
                                                                        <span>Dodaj Uplatu</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table class="user-list-table table dataTable no-footer dtr-column" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                                        <thead class="thead-light">
                                                        <tr role="row">
                                                            <th class="control sorting_disabled" rowspan="1" colspan="1" aria-label="">ID</th>
                                                            <th class="sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-sort="descending" aria-label="Email: activate to sort column ascending">Kolicina</th>
                                                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Role: activate to sort column ascending">Datum</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody v-if="!loadingUplate">
                                                        <tr class="odd" v-for="uplata in uplate" :key="uplata.id">
                                                            <td valign="top" colspan="1">{{uplata.id}}</td>
                                                            <td valign="top" colspan="1">{{uplata.value}}</td>
                                                            <td valign="top" colspan="1">{{uplata.created_at}}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <!-- Modal to add new user starts-->
                                            <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in">
                                                <div class="modal-dialog">
                                                    <form class="add-new-user modal-content pt-0" novalidate="novalidate">
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                                                        <div class="modal-header mb-1">
                                                            <h5 class="modal-title" id="exampleModalLabel">New User</h5>
                                                        </div>
                                                        <div class="modal-body flex-grow-1">
                                                            <div class="form-group">
                                                                <label class="form-label" for="basic-icon-default-fullname" >Kolicina</label>
                                                                <input type="text" class="form-control dt-full-name" id="basic-icon-default-fullname" v-model="kolicinauplate" placeholder="200 KM" name="user-fullname" aria-label="200 KM" aria-describedby="basic-icon-default-fullname2">
                                                            </div>
                                                            <button type="button" class="btn btn-primary mr-1 data-submit waves-effect waves-float waves-light" v-on:click="DodajUplatu()">Uplati</button>
                                                            <button type="reset" class="btn btn-outline-secondary waves-effect" data-dismiss="modal">Prekini</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <!-- Modal to add new user Ends-->
                                        </div>
                                        <!-- list section end -->
                                    </section>
                                </div>
                                <div class="tab-pane" id="profile" aria-labelledby="profile-tab" role="tabpanel">
                                            <!-- Basic Tabs starts -->
                                            <div class="col-xl-12 col-lg-12">
                                                <div class="card-body">
                                                    <ul class="list-group">
                                                        <li v-for="Test in Testovi" :key="Test.id" v-on:click="TestoviPregled(Test)" class="list-group-item" v-bind:class="{'correct':Test.polagao&&Test.polozio,'incorrect':Test.polagao&&!Test.polozio}">{{Test.name}}
                                                            <svg v-if="Test.polagao&&Test.polozio" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                            <svg v-if="Test.polagao&&!Test.polozio" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import api from "../api"
  export default {
    name: 'korisniciView',
    components: {
    },
    mounted(){
        let ID=this.$route.params.id;
        this.getUser(ID).then(data => {
            this.user=data.data.User;
            this.getUserUplate(this.user.id).then(data => {
                this.uplate=data.data.uplate;
                this.loadingUplate=false;
            });

        });
        this.GetTestovi(ID).then(data => {
            console.log(data.data.Tests);
            let TestoviAll=data.data.Tests;
            this.Testovi = TestoviAll.filter(Test => Test.polagao ===true);
            this.loading=false;
        });
    },
      created(){
          let ID=this.$route.params.id;
          this.getUser(ID).then(data => {
              this.user=data.data.User;
              this.getUserUplate(this.user.id).then(data => {
                  this.uplate=data.data.uplate;
                  this.loadingUplate=false;
              });

          });
          this.GetTestovi(ID).then(data => {
              console.log(data.data.Tests);
              let TestoviAll=data.data.Tests;
              this.Testovi = TestoviAll.filter(Test => Test.polagao ===true);
              this.loading=false;
          });
      },
    data(){
        return {
            user:null,
            Testovi:null,
            loading:true,
            loadingUplate:true,
            kolicinauplate:"",
        }
    },
    methods:{
        async getUser(id) {
            return api.getKorisnik(id);
        },
        async GetTestovi(id) {
            return api.GetTestoviUser(id);
        },
        async getUserUplate(id) {
            return api.getKorisnikUplate(id);
        },
        DodajUplatu(){
            let UplataValue=this.kolicinauplate;
            let ID=this.$route.params.id;
            //this.uplate= api.postKorisnikUplata(ID,UplataValue).data.data.uplate;
            this.$router.go(0);
        },
        TestoviPregled(Test)
        {
            let ID=this.$route.params.id;
            this.$router.push({ name: 'TestoviPregled', params: { id: Test.id,uid:ID } })
        }
    }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    li.nav-item {
        list-style-type: none;

    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: red;
        background-color: transparent;
        border-color: #DAE1E7 #DAE1E7 transparent;
    }
    .nav-tabs .nav-link {
        color: red;
    }
    .nav-tabs .nav-link:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(
                        30deg
                , red, rgba(173 , 51, 51, 1)) !important;
        transition: transform 0.3s;
        transform: translate3d(0, 150%, 0);
    }
    .card-body {
        padding: 0px;
        ul.list-group {
            color: red;
            font-weight: bold;
        }
        li.list-group-item.incorrect {
            background: #f44336;
            color: white;
        }
        li.list-group-item.correct {
            background: #8bc34a;
        }
    }
    .card.user-card {
        padding: 20px;
    }
</style>
